import React, { useState, useEffect } from 'react';
import './App.css'; // Import the CSS file
import io from 'socket.io-client';

// Update the Socket.IO client connection URL
const socket = io('https://wikiguessr-6d5a3c1aebf6.herokuapp.com'); // Adjust the URL as necessary

const App = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [articleContent, setArticleContent] = useState(null); // Keep articleContent client-side
  const [startArticle, setStartArticle] = useState(null);
  const [endArticle, setEndArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]); // Keep history client-side
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });
  const [isRunning, setIsRunning] = useState(false);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [showRules, setShowRules] = useState(true);
  const [gameDifficulty, setGameDifficulty] = useState('');
  const [scoreboard, setScoreboard] = useState([]); // To store the scoreboard data
  const [winner, setWinner] = useState('');

  // New state variables for lobby functionality
  const [lobbyId, setLobbyId] = useState('');
  const [isHost, setIsHost] = useState(false);
  const [username, setUsername] = useState('');
  const [openLobbies, setOpenLobbies] = useState([]);
  const [lobbyPlayers, setLobbyPlayers] = useState([]);

  // Timer effect: increments time every second when the timer is running
  useEffect(() => {
    let timerInterval = null;
    if (isRunning) {
      timerInterval = setInterval(() => {
        setTime((prevTime) => {
          let { minutes, seconds } = prevTime;
          seconds += 1;
          if (seconds >= 60) {
            seconds = 0;
            minutes += 1;
          }
          return { minutes, seconds };
        });
      }, 1000);
    } else if (!isRunning && timerInterval) {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval); // Clean up interval on unmount or stop
  }, [isRunning]);

  // Scroll to the top of the article when article content changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [articleContent]);

  // Socket.IO event listeners
  useEffect(() => {
    // Request the list of open lobbies when the component mounts
    socket.emit('getOpenLobbies');

    // Listen for updates to the lobby list
    socket.on('openLobbies', (lobbies) => {
      setOpenLobbies(lobbies);
    });

    socket.on('lobbyListUpdated', (lobbies) => {
      setOpenLobbies(lobbies);
    });

    // Listen for game state updates (this should not update the articleContent)
    socket.on('gameStateUpdate', (gameState) => {
      if (gameState) {
        setStartArticle(gameState.startArticle);
        setEndArticle(gameState.endArticle);
        setTime(gameState.time);
        setGameCompleted(gameState.gameCompleted);
        setIsRunning(gameState.isRunning);
        setShowRules(gameState.showRules);

        // Fetch the start article for the player
        if (!gameState.gameCompleted) {
          fetchArticle(gameState.startArticle).then((result) => {
            if (!result.error) {
              setArticleContent(result);
            }
          });
        }
      }
    });

    // Listen for game result (when a player wins)
    socket.on('gameResult', ({ winner, players }) => {
      setWinner(winner);
      setScoreboard(players);
      setIsRunning(false); // Stop the timer for all players
      setGameCompleted(true); // Mark the game as completed
    });

    // Listen for lobby players updates
    socket.on('lobbyPlayersUpdate', (players) => {
      setLobbyPlayers(players);
    });

    // Clean up the listeners on unmount
    return () => {
      socket.off('openLobbies');
      socket.off('lobbyListUpdated');
      socket.off('gameStateUpdate');
      socket.off('gameResult');
      socket.off('lobbyPlayersUpdate');
    };
  }, []);

  // Handle creating a new lobby
  const handleCreateLobby = () => {
    if (!username) {
      const inputUsername = prompt('Enter your username:');
      if (!inputUsername) return;
      setUsername(inputUsername);
      socket.emit('createLobby', { username: inputUsername }, ({ lobbyId }) => {
        setLobbyId(lobbyId);
        setIsHost(true);
      });
    } else {
      socket.emit('createLobby', { username }, ({ lobbyId }) => {
        setLobbyId(lobbyId);
        setIsHost(true);
      });
    }
  };

  // Handle joining a lobby
  const handleJoinLobby = (lobbyIdToJoin) => {
    if (!username) {
      const inputUsername = prompt('Enter your username:');
      if (!inputUsername) return;
      setUsername(inputUsername);
      socket.emit(
        'joinLobby',
        { lobbyId: lobbyIdToJoin, username: inputUsername },
        (response) => {
          if (response.success) {
            setLobbyId(lobbyIdToJoin);
            setIsHost(false);
          } else {
            alert(response.message);
          }
        }
      );
    } else {
      socket.emit(
        'joinLobby',
        { lobbyId: lobbyIdToJoin, username },
        (response) => {
          if (response.success) {
            setLobbyId(lobbyIdToJoin);
            setIsHost(false);
          } else {
            alert(response.message);
          }
        }
      );
    }
  };

  // Handle leaving a lobby
  const handleLeaveLobby = () => {
    socket.emit('leaveLobby', { lobbyId });
    setLobbyId('');
    setIsHost(false);
    // Reset game state if needed
    setIsRunning(false);
    setTime({ minutes: 0, seconds: 0 });
    setHistory([]);
    setShowRules(true);
    setStartArticle(null);
    setEndArticle(null);
    setArticleContent(null);
    setGameCompleted(false);
    setUsername('');
    setLobbyPlayers([]);
  };

  // Handle game reset and leave the lobby
  const handleGiveUp = () => {
    setIsRunning(false); // Stop the timer
    setTime({ minutes: 0, seconds: 0 }); // Reset the timer
    setHistory([]); // Clear history
    setShowRules(true); // Show the rules again
    setStartArticle(null); // Reset articles
    setEndArticle(null);
    setArticleContent(null); // Clear the article content
    setGameCompleted(false); // Reset game completion state
    handleLeaveLobby(); // Leave the lobby
  };

  // Handle starting the game
  const handleStartGameClick = async () => {
    if (!isHost) return; // Only the host can start the game
    setHistory([]); // Empty the history array
    setTime({ minutes: 0, seconds: 0 }); // Reset the timer
    setIsRunning(true); // Start the timer
    setGameCompleted(false); // Reset game completion state
    setShowRules(false); // Hide rules when game starts
    socket.emit('startGame', { lobbyId }); // Emit the startGame event to the server
  };


  // Handle searching for a Wikipedia article (no effect on start or end article)
  const handleSearch = async (event) => {
    event.preventDefault();
    if (!searchQuery) return; // If there's no search query, do nothing

    try {
      setLoading(true);
      const response = await fetch(
        `https://en.wikipedia.org/w/api.php?action=query&list=search&format=json&srsearch=${encodeURIComponent(
          searchQuery
        )}&origin=*`
      );
      const data = await response.json();

      if (data.query.search.length > 0) {
        const firstResultTitle = data.query.search[0].title;
        const result = await fetchArticle(firstResultTitle);
        if (!result.error) {
          setArticleContent(result); // Display search result article
          setHistory([]); // Clear history when a new search is performed
        }
      } else {
        setArticleContent({
          title: 'No Results',
          content: 'No articles found for this search query.',
        });
      }
    } catch (error) {
      setArticleContent({
        title: 'Error',
        content: 'An error occurred while searching for the article.',
      });
    } finally {
      setLoading(false);
    }
  };

  // Unified function to fetch and display article based on a title
  const fetchArticle = async (title) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://en.wikipedia.org/w/api.php?action=parse&format=json&page=${encodeURIComponent(
          title
        )}&prop=text&origin=*`
      );
      const data = await response.json();
      let content = data.parse.text['*'];

      // Create a temporary DOM element to parse the HTML and manipulate it
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;

      // Find and remove elements with the exact class
      const elementsToRemove = tempDiv.querySelectorAll(
        '[class="box-Multiple_issues plainlinks metadata ambox ambox-content ambox-multiple_issues compact-ambox"]'
      );
      elementsToRemove.forEach((element) => {
        element.remove();
      });

      return {
        title: title,
        content: tempDiv.innerHTML,
        error: false,
      };
    } catch (error) {
      return {
        title: 'Error',
        content: 'An error occurred while fetching data.',
        error: true,
      };
    } finally {
      setLoading(false);
    }
  };

  // Handle link clicks and check if the end article has been reached
  const handleLinkClick = async (event) => {
    const link = event.target.closest('a');
    if (link) {
      event.preventDefault(); // Prevent default link behavior

      const linkUrl = link.href; // Get the full URL of the clicked link
      const linkText = link.textContent.trim(); // Get the text of the clicked link

      // Extract the article title from the URL (assuming Wikipedia link structure)
      let articleTitle = new URL(linkUrl).pathname.split('/').pop();

      // Replace any underscores with spaces to format the article title correctly
      articleTitle = articleTitle.replace(/_/g, ' ');

      const result = await fetchArticle(articleTitle); // Fetch article based on the article title from the URL

      if (!result.error) {
        setArticleContent(result); // Update article content with the link result

        // Update history
        setHistory((prevHistory) => [...prevHistory, linkText]);

        // Emit the updated history to the server
        socket.emit('updatePlayerHistory', { lobbyId, playerName: username, history: [...history, linkText] });

        // Check if the user has reached the end article
        if (articleTitle === endArticle) {
          socket.emit('playerWin', { lobbyId, playerName: username, history });
        }
      }
    }
  };

  // Handle game difficulty selection
  const handleDifficultySelect = (difficulty) => {
    setGameDifficulty(difficulty); // Set the selected difficulty
  };

  return (
    <div className="App">
      <header className="header">
        <h1>Wikiguessr</h1>
        <form onSubmit={handleSearch} className="search-form">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search Wikipedia"
          />
          <button type="submit">Search</button>
        </form>
      </header>
      <div className="content">
        {gameCompleted ? (
          // Render scoreboard when game is completed
          <div className="scoreboard">
            <h3>Winner: {winner}</h3>
            <table>
              <thead>
                <tr>
                  <th>Player</th>
                  <th>History</th>
                </tr>
              </thead>
              <tbody>
                {scoreboard.map((player, index) => (
                  <tr key={index}>
                    <td>{player.username}</td>
                    <td>
                      <ul>
                      <li><strong>{startArticle}</strong></li>
                        {player.history.map((click, i) => (
                          <li key={i}>{click}</li>
                        ))}
                        {/* Show endArticle only if player is the winner */}
                        {player.username === winner && <li><strong>{endArticle}</strong></li>}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Add Return to Home Button */}
            <button onClick={handleGiveUp} className="return-btn">
              Return
            </button>
          </div>

        ) : showRules ? (
          <div className="rules-section">
            <h2>Game Rules</h2>
            <p>
              The goal of the game is to navigate from the Start article to the End
              article using only Wikipedia links. Click on links within the articles
              to move from one article to another. Try to reach the End article as
              quickly as possible!
            </p>
            <div className="difficulty-buttons">
              <button
                onClick={() => handleDifficultySelect('easy')}
                className="difficulty-btn"
              >
                Easy
              </button>
              <button
                onClick={() => handleDifficultySelect('medium')}
                className="difficulty-btn"
              >
                Medium
              </button>
              <button
                onClick={() => handleDifficultySelect('hard')}
                className="difficulty-btn"
              >
                Hard
              </button>
            </div>
            {lobbyId && (
              <div className="lobby-info">
                <p>Username: {username}</p>
                <h3>Players in Lobby:</h3>
                <ul>
                  {lobbyPlayers.map((player, index) => (
                    <li key={index}>
                      {player.username} {player.isHost && '(HOST)'}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!lobbyId && (
              <>
                <div className="lobby-controls">
                  <button onClick={handleCreateLobby} className="difficulty-btn">
                    Create Lobby
                  </button>
                </div>
                {openLobbies.length > 0 && (
                  <div className="open-lobbies">
                    <h3>Open Lobbies:</h3>
                    <ul>
                      {openLobbies.map((lobby) => (
                        <li key={lobby.lobbyId}>
                          Host: {lobby.hostUsername} - Players: {lobby.playerCount}{' '}
                          {lobby.gameStarted && '(GAME IN PROGRESS)'}
                          {!lobby.gameStarted && (
                            <button
                              onClick={() => handleJoinLobby(lobby.lobbyId)}
                              className="difficulty-btn"
                            >
                              Join
                            </button>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}
            {lobbyId && (
              <button onClick={handleLeaveLobby} className="difficulty-btn">
                Leave Lobby
              </button>
            )}
            {isHost && (
              <button onClick={handleStartGameClick} className="difficulty-btn">
                Start Game
              </button>
            )}
          </div>
        ) : (
          <>
           <div
              className="article-section"
              onClick={!gameCompleted ? handleLinkClick : null}
            >
              {articleContent ? (
                <>
                  <h2>{articleContent.title}</h2>
                  <div
                    className="article-content"
                    dangerouslySetInnerHTML={{
                      __html: articleContent.content,
                    }}
                  />
                </>
              ) : (
                <p>{loading ? 'Loading...' : 'No article found'}</p>
              )}
            </div>


            {!showRules && (
              <div className="game-controls">
                <div className="article-info">
                  <div className="article-line">
                    <strong>Start Article:</strong>
                    <span>{startArticle}</span>
                  </div>
                  <div className="article-line">
                    <strong>End Article:</strong>
                    <span>{endArticle}</span>
                  </div>
                </div>
                <p className="timer">
                  {`${time.minutes}:${time.seconds < 10 ? '0' : ''}${time.seconds}`}
                </p>
                <button onClick={handleGiveUp}>
                  {gameCompleted ? 'Return' : 'Give Up'}
                </button>
                <div className="lobby-players">
                  <h3>Players in Lobby:</h3>
                  <ul>
                    {lobbyPlayers.map((player, index) => (
                      <li key={index}>
                        {player.username} {player.isHost && '(HOST)'}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="history">
                  <h3>History:</h3>
                  <ul>
                    {history.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default App;
